import React from 'react';
import { Image } from 'react-bootstrap';
// import intro from 'product-foundry-site-intro-threejs';
import {default as initAnimation} from "pf-birds";
import parse from 'html-react-parser';
import Api from '../../../api';

class Visual extends React.Component {

  constructor(props) {
    super(props);
    this.animRef = React.createRef();
    this.api = new Api();
    this.env = this.api.getEnv();
    this.state = {
      imagemapsvg: null
    }
  }

  componentDidMount = () => {
    this.animRef.current!==null && initAnimation(this.animRef.current, this.env.texts);
    const imagemap = this.props.imagemap;
    if (imagemap) {
      fetch(`https://api.townmaking.com/download/${imagemap}?Tenant=clay&qualifier=presentation`).then(res => {
        res.text().then(svg => {
          this.setState({ imagemapsvg: svg.substring(svg.indexOf("<svg"), svg.length) });
        })
      })
    }
  }

  render() {
    const { image, title, clickLink, module, role, overlay, link, imagemap } = this.props;
    const classes = link ? "linked" : "";
    const isModule = !!module;
    const isImage = image && image.indexOf("resource:image") > -1;
    const bg = imagemap ? { backgroundImage: "url(https://api.townmaking.com/download/" + image + "?Tenant=clay&qualifier=presentation)" } : {};
    return (
      <div className={`visual-container overlay has-bg-img ${classes}`} style={bg}>
        {isImage && <React.Fragment>
          {!imagemap && <Image src={`https://api.townmaking.com/download/${image}?Tenant=clay&qualifier=presentation`}
            fluid className={`visual card-img ${classes} ${role}`} link={link} onClick={() => !overlay && clickLink(link) }/>}
          {imagemap && this.state.imagemapsvg && parse(this.state.imagemapsvg)}
          {overlay && <div className="card-img-overlay" onClick={() => clickLink(link)} >
            {title && <h1>{title}</h1>}
            {<h2 className="m-0">{overlay}</h2>}
          </div>}
        </React.Fragment>
        }
        {isModule && <div className="animRef" ref={this.animRef}></div>}
      </div>
    )
  }
}
export default Visual;